<template>
   <div class="p-10">
      <section>
         <div class="grid grid-cols-3 gap-5">
            <div class="p-5">
               <SideNav />
            </div>
            <div class="col-span-2">
               <router-view></router-view>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
   import SideNav from './components/sidenav'
   export default {
      name: "Profile",

      components: {
         SideNav,
      },

      data() {
         return {
            
         }
      },

      methods: {
      
      }
   }
</script>