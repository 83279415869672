<template>
   <div>
      <router-link to="/account/user/profile/" class="block py-1 px-5 my-4 hover:text-indigo-900 font-medium" :class="$route.path === '/account/user/profile/' ? 'border-l-2 border-indigo-900 text-indigo-900 font-bold' : '' ">Profile Settings</router-link>
      <router-link to="/account/user/profile/banking" class="block py-1 px-5 my-4 hover:text-indigo-900 font-medium" :class="$route.path === '/account/user/profile/banking' ? 'border-l-2 border-indigo-900 text-indigo-900 font-bold' : '' ">Bank Settings</router-link>
      <router-link to="/account/user/profile/contacts" class="block py-1 px-5 my-4 hover:text-indigo-900 font-medium" :class="$route.path === '/account/user/profile/contacts' ? 'border-l-2 border-indigo-900 text-indigo-900 font-bold' : '' ">My Contacts</router-link>
   </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
   name: "SideNav",

   data() {
      return {
         isLoading: false
      }
   },

   methods: {
      
   },

   computed: {
      ...mapGetters("auth", ["user"]),
   },
}
</script>